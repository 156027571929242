<template>
  <div class="w-full h-screen bg-[#F1FCFB] dark:bg-cs-dark-background relative">
    <!--<NuxtLoadingIndicator :throttle="0" color="#39C0C4" />-->
    <div class="absolute top-[20px] md:top-[32px] lg:top-[40px] m-auto left-0 right-0 w-fit lg:left-[70px] lg:right-auto">
      <NuxtLink to="/landing">
        <CardanoSpotLogo class="dark:hidden" />
        <CardanoSpotLogoLight class="hidden dark:block" />
      </NuxtLink>
    </div>
    <div class="h-full">
      <suspense>
        <slot />
      </suspense>
    </div>
  </div>
</template>

<script setup type="ts">

const route = useRoute();
const { $getAbsoluteUrl } = useNuxtApp();

useHead(() => ({
  link: [
    {
      rel: "canonical",
      href: $getAbsoluteUrl() + route.path
    }
  ]
}));
</script>
